import Loading from '../Loading'
import { FilterableDataTable } from '../tables/FilterableDataTable'
import { generateColumns } from 'src/components/dialogs/SampleInformationPeptideListTableColumn'
import useDialogStore from 'src/store/dialogStore'
import { useContext, useEffect, useState } from 'react'
import { PaginatedPeptidesContext } from '../contexts/PaginatedPeptidesContext'

const SampleInformationPeptideListTable = () => {
  const { closeDialog } = useDialogStore()
  const [ columns, setColumns ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const paginatedPeptides = useContext(PaginatedPeptidesContext)

  useEffect(() => {
    setColumns(generateColumns(closeDialog))
    setLoading(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [ paginatedPeptides, closeDialog ])

  if (loading) return <Loading label='Data is loading...' />

  return (
    <FilterableDataTable columns={columns} data={paginatedPeptides} />
  )
}

export default SampleInformationPeptideListTable
