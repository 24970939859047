enum ApiPath {
  Plot = '/plot',
  PlotProteins = '/plotpy',
  Peptides = '/peptides',
  Categories = '/categories',
  Samples = '/samples',
  SimSearchValidation = '/simsearch/validation',
  SimSearchScoring = '/simsearch/scoring',
  Proteins = '/proteins',
  PositionalPlot = '/proteins/posplot',
  PeptidePositions = '/proteins/positions',
}

export default ApiPath