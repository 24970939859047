import ApiPath from '../config/apiPath'
import { API, Auth } from 'aws-amplify'
import PlotApiResponse, { PositionalPlotApiResponse } from '../types/responses/PlotApiResponse'
import PeptidesApiResponse from '../types/responses/PeptidesApiResponse'
import PeptideApiResponse from '../types/responses/PeptideApiResponse'
import CategoryApiResponse, { CategoryApiItem } from '../types/responses/CategoryApiResponse'
import addFlashMessage from '../lib/messages'
import SampleApiResponse from '../types/responses/SampleApiResponse'
import SamplesApiResponse from '../types/responses/SamplesApiResponse'
import Category from '../types/Category'
import ProteinsApiResponse from '../types/responses/ProteinsApiResponse'

export const getPeptide = (peptide: string, filter: string): Promise<PeptideApiResponse> => get(`${ApiPath.Peptides}/${peptide}`, { filter })

export const getSample = (sample: number): Promise<SampleApiResponse> => get(`${ApiPath.Samples}/${sample}`)

export const getSamples = (): Promise<SamplesApiResponse> => get(ApiPath.Samples)

export const getCategories = (filter: string): Promise<CategoryApiResponse> => get(ApiPath.Categories, { filter })
  .then(categories => categories.reduce((acc: Record<string, CategoryApiItem>, category: Category) => {
    const name = category.category_name
    const type = category.category_type
    const samples = category.samples_in_category

    acc[name] = { name, size: 0, intensity: 0, sampleSum: 0, type, samples }

    return acc
  }, {}))
export const getPeptideByProteinGene = (name: string, filter: string): Promise<PeptideApiResponse> => get(`${ApiPath.Peptides}`, { name, filter })

export const getPositionalPlot = (proteinId: string, hlaFilter: string): Promise<PositionalPlotApiResponse> => get(`${ApiPath.PositionalPlot}/${proteinId}`, { hlaFilter })

export const getProteins = (searchType: string, searchTerm: string): Promise<ProteinsApiResponse> => get(`${ApiPath.Proteins}`, { searchType, searchTerm })

export const getPeptidePositions = (searchTerm: string, hlaFilter: string): Promise<ProteinsApiResponse> => get(`${ApiPath.PeptidePositions}/${searchTerm}`, { hlaFilter })

export const postPeptides = async (peptideList: string[], filter: string): Promise<PeptidesApiResponse> => {
  const path = ApiPath.Peptides

  const init = {
    headers: {
      Authorization: await getAccessToken(),
      'content-type': 'application/json',
    },
    queryStringParameters: { filter },
    body: { peptideList }
  }

  return await API.post('peptide', path, init).catch(errorHandler)
}

export const postSampleList = async (peptide: string, hlaFilter: string): Promise<PlotApiResponse> => {
  const path = ApiPath.Plot

  const init = {
    headers: {
      Authorization: await getAccessToken(),
      'content-type': 'application/json',
    },
    body: { peptideString: peptide, hlaFilter }
  }

  return await API.post('peptide', path, init)
}

export const postProteinPlots = async (searchTerm: string, hlaFilter: string): Promise<PlotApiResponse> => {
  const init = {
    headers: {
      Authorization: await getAccessToken(),
      'content-type': 'application/json',
    },
    body: { searchType: 'protein', searchTerm: searchTerm.toUpperCase(), hlaFilter }
  }

  return await API.post('peptide', ApiPath.PlotProteins, init)
}

export const postSimSearchValidation = async (peptide: string, xScanMatrix: string): Promise<any> => {
  const init = {
    headers: {
      Authorization: await getAccessToken(),
      'content-type': 'application/json',
    },
    body: { xScan: xScanMatrix }
  }
  
  return await API.post('peptide', `${ApiPath.SimSearchValidation}/${peptide}`, init).catch(errorHandler)
}

// Helper methods

export const errorHandler = (error) => {
  let message = 'An unknown error occurred.'
  if (error.response !== undefined) message = error.response.data.error || error.response.data.message
  addFlashMessage('An error occurred', `Message: ${message}`, 'error')
}

const getAccessToken = async () => {
  const session = await Auth.currentSession()

  return session.getAccessToken().getJwtToken()
}

const getInitForGetRequests = async (queryStringParameters: object) => ({
  headers: {
    Authorization: await getAccessToken(),
    'content-type': 'application/json',
  },
  queryStringParameters
})

const get = async (path: string, queryStringParameters: object = {}) => {
  const init = await getInitForGetRequests(queryStringParameters)

  return await API.get('peptide', path, init).catch(errorHandler)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const post = async (path: string, queryStringParameters: object = {}) => {
  const init = await getInitForGetRequests(queryStringParameters)

  return await API.post('peptide', path, init).catch(errorHandler)
}