import React from 'react'
import useProteinPlots from '../../hooks/useProteinPlots'
import PlotImage from './PlotImage'

function ProteinPlots({ searchTerm, searchType, hlaFilter }: {searchTerm: string, searchType: string, hlaFilter: string}) {
  const { isLoading, data } = useProteinPlots(searchType, searchTerm, hlaFilter)

  return (
    <PlotImage isLoading={isLoading} data={data} />
  )

}

export default ProteinPlots